"use client";

import { signIn } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useState } from "react";
import AuthForm from "../AuthForm/AuthForm";

type LoginFormProps = {
  message?: string | null;
};

const LoginForm = ({ message }: LoginFormProps) => {
  const router = useRouter();
  const [error, setError] = useState("");

  const handleFormSubmit = async (data: {
    email: string;
    password: string;
  }) => {
    const result = await signIn("credentials", {
      redirect: false,
      email: data.email,
      password: data.password,
    });

    if (result?.error) {
      setError("Invalid credentials");
    } else if (result?.ok) {
      router.push("/monitoringtool");
      router.refresh();
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md">
        {message && (
          <div className="p-4 mb-4 text-blue-700 bg-blue-100 rounded-lg">
            {message}
          </div>
        )}
        {error && (
          <div className="p-4 mb-4 text-red-700 bg-red-100 rounded-lg">
            {error}
          </div>
        )}
        <div className="overflow-hidden bg-white rounded-lg shadow-2xl">
          <div className="p-8">
            <h1 className="mb-8 text-2xl font-bold text-center text-gray-700">
              Login to Monitoring Tool
            </h1>
            <AuthForm
              onSubmit={handleFormSubmit}
              title=""
              buttonText="Sign In"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
